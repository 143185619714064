// console.log('Ready javascript');
//var imagesLoaded = require('imagesloaded');


// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);


// When the user scrolls the page, execute myFunction
window.onscroll = function() { myFunction(); };

// Get the header
var body = document.getElementById("body");

// Get the offset position of the navbar
var sticky = body.offsetTop + 50;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  //if (window.pageYOffset > sticky && window.innerWidth > 1120) {
    if (window.pageYOffset > sticky) {
    // document.getElementById("main").style.paddingTop = header.clientHeight + ".px";
    body.classList.add("is-active-scroll");
  } else {
    // document.getElementById("main").style.paddingTop = 0;
    body.classList.remove("is-active-scroll");
  }
}


$("a").on('click', function(event) {
  if (this.hash !== "") {
    var hash = this.hash;
    var headerHeight = document.getElementsByTagName("header");
    if (headerHeight[0].classList != "fixed"){
      var topPage = $(hash).offset().top;
    } else {
      var topPage = $(hash).offset().top;
    }
    $('#body').removeClass('is-active-menu');
    $('#js-header__menu-mobile , .header__layer').removeClass('is-active');
    $('html, body').animate({
      scrollTop: topPage
    }, 700, function(){
      window.location.hash = hash;
      var archos = window.location.hash.substr(1);
    });
  } 
});

jQuery( document ).ready(function( $ ) {

$('#footer .js-eventClick-open-child').click(function(){
  $(this).toggleClass('is-open');
  $(this).siblings().removeClass('is-open');
});

$('#header .js-eventClick-open-child').click(function(){
  if ($('#body').hasClass('is-active-menu')){
    $(this).toggleClass('is-open');
    $(this).siblings().removeClass('is-open');
  }
});

$(document).keyup(function(e) {
  if ($('#body').hasClass('is-active-menu')) {
    if (e.keyCode === 27) { $('#js-header__menu-mobile').click();}
  }
});


  $('#js-body__header__menu-mobile').click(function(){
    $('#js-header__menu-mobile').click();
  });

  $('.e-js-slider').each(function() {
    var $slider = $(this);
    var $pagination = $slider.siblings('.e-js-slider__aside.pagination');
    
    $slider.slick({
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 4500,
      infinite: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: 400,
      adaptiveHeight: true,
      vertical: true,
      slidesToScroll: 1,
      verticalSwiping: true,
      responsive: [
        {
          breakpoint: 768, // Cambia aquí el punto de quiebre según tus necesidades
          settings: {
            vertical: false,
            verticalSwiping: false
          }
        }
      ]
    });

    $pagination.on('click', '.pagination-item', function() {
      var index = $(this).index();
      $slider.slick('slickGoTo', index);
      $pagination.find('.pagination-item').removeClass('active');
      $(this).addClass('active');
    });

    $slider.on('afterChange', function(event, slick, currentSlide) {
      $pagination.find('.pagination-item').removeClass('active');
      $pagination.find('.pagination-item').eq(currentSlide).addClass('active');
    });
  });



  $slick = $('#js-c-banner-home__slider');
  $slick.slick({
    arrows: false,
    dots:false,
    autoplay: true,
    autoplaySpeed: 4500,
    infinite: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 450,
    adaptiveHeight: true,
    vertical: true,
    slidesToScroll: 1,
    verticalSwiping: true,
    responsive: [
    {
      breakpoint: 1020, // Cambia aquí el punto de quiebre según tus necesidades
      settings: {
        vertical: false,
        verticalSwiping:false
      }
    }
    ]
  });
  

  $slick.on('click', '.pagination-item', function() {
    var index = $(this).index();
    $slick.slick('slickGoTo', index);
    $('.pagination-item').removeClass('active');
    $(this).addClass('active');
  });

  // Actualizar la paginación después de cada cambio de slide
  $slick.on('afterChange', function(event, slick, currentSlide) {
    $('.pagination-item').removeClass('active');
    $('.pagination-item').eq(currentSlide).addClass('active');
  });

  

  $('.c-banner-home__aside .pagination-item').click(function() {
    var index = $(this).index();
    $slick.slick('slickGoTo', index);
    $('.pagination-item').removeClass('active');
    $(this).addClass('active');
  });




  
  $('#js-header__menu-mobile').click(function(){
    $('#body').toggleClass('is-active-menu');
    $(this).toggleClass('is-active');
    $('.header__layer').toggleClass('is-active');
  });

  $('.js-carousel').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    dots:false,
    responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: false,
        //centerMode: true,
        // centerPadding: '80px',
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 400,
      settings: {
        dots: false,
        //centerMode: true,
        // centerPadding: '80px',
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },

    ]
  });


});


