// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('slick-carousel');
require('@fancyapps/fancybox');

// Scripts
require('./modules/scripts');



// https://michalsnik.github.io/aos/
jQuery( document ).ready(function( $ ) {
  var AOS = require('aos');
  AOS.init({duration:800,easing:'slide',once:true});
});














